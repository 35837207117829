import React from "react"
import HomePageLayout from "../components/layouts/HomePageLayout"
import classes from "./join-us.module.css"
import Footer from "../components/footer"
import { HeaderVariants } from "../components/header"

import joinUsWoman from "../images/join-us/join-us-woman.png"

import shakeIcon from "../images/join-us/2.png"
import peopleIcon from "../images/join-us/1.png"
import worldIcon from "../images/join-us/3.png"
import { Button } from "../components/Button"

function JoinUs(props) {
  return (
    <HomePageLayout pageTitle="Join Us" headerVariant={HeaderVariants.dark}>
      <div className={classes.top}>
        <span className={[classes.circle, classes.circleLeft].join(" ")} />
        <span className={[classes.circle, classes.circleTop].join(" ")} />
        <span className={[classes.circle, classes.circleRight].join(" ")} />
        <img src={joinUsWoman} alt="" className={classes.topImage} />
        <h3 className={classes.topHeader}>
          JOIN OUR <span className={classes.topHeaderBorderText}>TEAM</span>
        </h3>
        <p className={classes.topSubtext}>
          Bring your super power onboard and join us in changing the world
        </p>
      </div>
      <section className={classes.whatItMeans}>
        <h1 className={classes.whatItMeansHeader}>
          what it means to work for us.
        </h1>
        <div className={classes.whatItMeansCards}>
          <div
            className={classes.whatItMeansCard}
            style={{ borderBottomColor: "#33E1FF" }}
          >
            <img
              src={peopleIcon}
              className={classes.whatItMeansCardIcon}
              alt=""
            />
            <h3 className={classes.whatItMeansCardHeader}>WE CARE ABOUT YOU</h3>
            <p className={classes.whatItMeansCardText}>
              We are passionate about you and your continuous personal and
              professional development. We offer a career, not a job, an
              opportunity to realize your full potential, unleash them and
              imprint your feet in the sands of time
            </p>
          </div>
          <div
            className={classes.whatItMeansCard}
            style={{ borderBottomColor: "#F36" }}
          >
            <img
              src={shakeIcon}
              alt=""
              className={classes.whatItMeansCardIcon}
            />
            <h3 className={classes.whatItMeansCardHeader}>CULTURE ABOVE ALL</h3>
            <p className={classes.whatItMeansCardText}>
              We value our culture above the fleeting pleasure of money. Our
              culture is our everything; it is the reason why we exist and
              breathe. Our culture pushes us to be open, correct each other in
              love and with respect, think up the most unbelievable and
              ingenious ideas, push each other to be the best we can be, churn
              out outstanding products and in the middle of it all, live our
              best possible lives.
            </p>
          </div>
          <div
            className={classes.whatItMeansCard}
            style={{ borderBottomColor: "#F3CA3E" }}
          >
            <img
              src={worldIcon}
              className={classes.whatItMeansCardIcon}
              alt=""
            />
            <h3 className={classes.whatItMeansCardHeader}>MAKE AN IMPACT</h3>
            <p className={classes.whatItMeansCardText}>
              Innovation is at the core of what we do. With us, you will be
              challenged daily to think outside the box, unleash your
              creativity, put a smile on people's faces and be a part of
              something great.
            </p>
          </div>
        </div>
      </section>
      <section className={classes.joinUs}>
        <h2 className={classes.joinUsHeader}>
          join us if you are awesome at what you do
        </h2>
        <div className={classes.joinUsLinkWrapper}>
          <Button to="/jobs">JOB OPENINGS</Button>
        </div>
      </section>
      <Footer />
    </HomePageLayout>
  )
}

export default JoinUs
